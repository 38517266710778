import React, { useState, useEffect } from 'react';
import './App.css';

import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";

// import Ticker from 'react-ticker'
 
import html2canvas from 'html2canvas';
import { GoogleSpreadsheet } from 'google-spreadsheet';

import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Dotdotdot from 'react-dotdotdot';
import Slider from "react-slick";

import Prismic from 'prismic-javascript';
import { Date, Link, RichText } from 'prismic-reactjs';

// import { findAllByTestId } from '@testing-library/react';


function App() {

  const [screenStartInit, setScreenStartInit] = useState(false);

  const [screenConfigInit, setScreenConfigInit] = useState(false);

  const [screenDataFetch, setScreenDataFetch] = useState(false);

  const [screenDataRefresh, setScreenDataRefresh] = useState(false);

  const [screenConfigJSON, setScreenConfigJSON] = useState(null);

  const [globalSheetsDataOutput, setGlobalSheetsDataOutput] = useState(null);

  const [globalPrismicDataOutput, setGlobalPrismicDataOutput] = useState(null);

  const [screenTimeoutID, setScreenTimeoutID] = useState(false);

  let screenshotTimeoutID = "";
  let dataRefreshTimeoutID = "";


  //standard CdM :: useEffect BLOCK..
  //app init on first load..

  useEffect(() => {

    console.log(">>> standard CdM BLOCK == first app init ..");

    if (screenStartInit === false) {
      console.log("setScreenStartInit = TRUE");
      setScreenStartInit(true);
    }

  }, []);


  //screenStartInit  CdM :: useEffect BLOCK..
  //load after "screenStartInit = true" setState..

  useEffect(() => {

    console.log(">>> screenStartInit CdM ..");

    if (screenStartInit === true) {

      let screenConfigURL = "";
      let screenConfigQueryString = "";

      // let xiboScreenID = "";

      // var screenConfigData = "";
    
      //MK DEV -- add dyn var from screen URL..


      screenConfigQueryString = window.location.search;
      console.log(screenConfigQueryString);


      if (screenConfigQueryString.indexOf("perth") >= 0) {
        screenConfigURL = process.env.PUBLIC_URL + "/config/screen_perth.json";
      }

      if (screenConfigQueryString.indexOf("perth_noapi") >= 0) {
        screenConfigURL = process.env.PUBLIC_URL + "/config/screen_perth_noapi.json";
      }

      if (screenConfigURL === "") {
        screenConfigURL = process.env.PUBLIC_URL + "/config/screen_not_valid.json";
      }

      fetch(screenConfigURL)
      .then(res => res.json())
      .then(
        (result) => {
          setScreenConfigJSON(result);

          setScreenConfigInit(true);
        },
        (error) => {
          console.log("App init failed.. NO config!");
          // console.log(error);
        }
      )
    
    }

  }, [screenStartInit]);


  //screenConfigInit CdM :: useEffect BLOCK..
  //load after "screenConfigInit = true" setState..

  useEffect(() => {
    console.log(">>> screenConfigInit CdM ..");
    console.log(screenConfigJSON);

    if (screenConfigInit === true) {
      // set screenStartInit to FALSE .. restart app init after 5 secs
      // timeoutID = setTimeout(() => {
      //   setScreenStartInit(false);
      // }, 5000);

      XiboRegisterDisplay(screenConfigJSON.xiboSoapUrl, screenConfigJSON.xiboScreenID);

      // trigger dataRefresh routine after X secs (to be read from screenConfigJSON..)
      // dataRefreshTimeoutID = setTimeout(() => {
      //   setScreenDataRefresh(true);
      // }, 15000);

      dataRefreshTimeoutID = setTimeout(() => {
        setScreenDataRefresh(true);
      }, screenConfigJSON.appRefreshCycleMs);

      //MK DEV -- set to read from ..:
      // screenConfigJSON.appRefreshCycleMs


      // DataFetch DISABLED WHILE OFFLINE / IN DEV..
      setScreenDataFetch(true);
    }

  }, [screenConfigInit]);


  //screenDataFetch CdM :: useEffect BLOCK..
  //load after "screenDataFetch = true" setState..

  useEffect(() => {
    console.log(">>> screenDataFetch CdM ..");

    if (screenDataFetch === true) {
      
      // GoogleSheetsConnector()
      // .then(result => setGlobalSheetsDataOutput(result))

      PrismicDataConnector()
      .then(result => setGlobalPrismicDataOutput(result))
    }

  }, [screenDataFetch]);


  //screenDataRefresh CdM :: useEffect BLOCK..
  //load after "screenDataRefresh = true" setState.. ie. Periodic data refresh of Sheets API/Xibo screenshot

  useEffect(() => {
    console.log(">>> screenDataRefresh CdM ..");

    if (screenDataRefresh === true) {

      // take/update screenshot for/on Xibo CMS after 0.5 secs

      //MK DEV -- 20200803 .. disable screenshot func
      screenshotTimeoutID = setTimeout(() => {
        XiboSendScreenshot(screenConfigJSON.xiboSoapUrl, screenConfigJSON.xiboScreenID);
      }, 500);

      //check if results data still similar structure
      //ie. arrLength compare.. quick check to see if operation to Sheets API failed
      

      // MK DEV -- 20200803
      
      // GoogleSheetsConnector()
      // .then(result => { if (result.length === globalSheetsDataOutput.length) { setGlobalSheetsDataOutput(result); }})

      PrismicDataConnector()
      .then(result => setGlobalPrismicDataOutput(result))
    }

  }, [screenDataRefresh]);


  //globalSheetsDataOutput CdM :: useEffect BLOCK..
  //load after "globalSheetsDataOutput" arr setState..

  useEffect(() => {
    console.log(">>> globalSheetsDataOutput CdM ..");

    if (screenDataRefresh === true) {

      //if scrDataRefresh eq TRUE, then dataRefresh just completed
      //reset timeoutID for next scrDataRefresh operation

      // trigger dataRefresh routine after X secs (to be read from screenConfigJSON..)
      // dataRefreshTimeoutID = setTimeout(() => {
      //   setScreenDataRefresh(false);
      // }, 15000);

      dataRefreshTimeoutID = setTimeout(() => {
        setScreenDataRefresh(true);
      }, screenConfigJSON.appRefreshCycleMs);

      //MK DEV -- set to read from ..:
      // screenConfigJSON.appRefreshCycleMs

      //set dataRefresh var to FALSE, ready to be pushed back to TRUE on next refreshTimeout
      setScreenDataRefresh(false);
    }

  }, [globalSheetsDataOutput]);


  //globalPrismicDataOutput CdM :: useEffect BLOCK..
  //load after "globalSheetsDataOutput" arr setState..

  useEffect(() => {
    console.log(">>> globalPrismicDataOutput CdM ..");

    if (screenDataRefresh === true) {

      //if scrDataRefresh eq TRUE, then dataRefresh just completed
      //reset timeoutID for next scrDataRefresh operation

      // trigger dataRefresh routine after X secs (to be read from screenConfigJSON..)
      // dataRefreshTimeoutID = setTimeout(() => {
      //   setScreenDataRefresh(false);
      // }, 15000);

      dataRefreshTimeoutID = setTimeout(() => {
        setScreenDataRefresh(true);
      }, screenConfigJSON.appRefreshCycleMs);

      //MK DEV -- set to read from ..:
      // screenConfigJSON.appRefreshCycleMs

      //set dataRefresh var to FALSE, ready to be pushed back to TRUE on next refreshTimeout
      setScreenDataRefresh(false);
    }

  }, [globalPrismicDataOutput]);


  if (screenConfigInit === true && screenDataFetch === true && globalPrismicDataOutput) {
    
    if (screenConfigJSON.screenOrientation === "landscape")
    {
      console.log("screenDisplayCycleMs ..: " + screenConfigJSON.screenDisplayCycleMs);

      const sliderSettings = {
        arrows: false,
        dots: false,
        fade: true,
        infinite: true,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: screenConfigJSON.screenDisplayCycleMs,
        slidesToShow: 1,
        slidesToScroll: 1
      };

      let slide1URLProc = globalPrismicDataOutput.data.slide_1.url + "&rm=minimal";
      let slide2URLProc = globalPrismicDataOutput.data.slide_2.url + "&rm=minimal";
      let slide3URLProc = globalPrismicDataOutput.data.slide_3.url + "&rm=minimal";
      let slide4URLProc = globalPrismicDataOutput.data.slide_4.url + "&rm=minimal";
      let slide5URLProc = globalPrismicDataOutput.data.slide_5.url + "&rm=minimal";


      return (
        <div className="Background">

          <Slider {...sliderSettings}>
            <div>
              <iframe src={slide1URLProc} frameBorder="0" width="1920" height="1080" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </div>
            <div>
              <iframe src={slide2URLProc} frameBorder="0" width="1920" height="1080" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </div>
            <div>
              <iframe src={slide3URLProc} frameBorder="0" width="1920" height="1080" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </div>
            <div>
              <iframe src={slide4URLProc} frameBorder="0" width="1920" height="1080" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </div>
            <div>
              <iframe src={slide5URLProc} frameBorder="0" width="1920" height="1080" allowFullScreen="true" mozallowfullscreen="true" webkitallowfullscreen="true"></iframe>
            </div>
          </Slider>
          
          <div className="ClearFloat"></div>
        </div>
        
      );
    }
  } else {
    return (
      <div className="App">
        LOADING...
      </div>
    );
  }

}

async function PrismicDataConnector() {

  let prismicDataOutput = [];

  // const col2Title = sheet.getCell(2, 1);
  // const col2Image = sheet.getCell(2, 2);
  // const col2Text = sheet.getCell(2, 3);

  // sheetsDataOutput[3] = col2Title.value;
  // sheetsDataOutput[4] = col2Image.value;
  // sheetsDataOutput[5] = col2Text.value;


  // Prismic API connection
  const apiEndpoint = 'https://signage-catercare.cdn.prismic.io/api/v2';
  const accessToken = ''; // This is where you would add your access token for a Private repository
  
  // const Client = Prismic.client(apiEndpoint, { accessToken });
  const Client = Prismic.client(apiEndpoint);
  
  const response = await Client.query(Prismic.Predicates.at('document.type', 'playlist'))
  
  if (response) {
    prismicDataOutput = response.results[0];
    console.log(response.results[0]);

  }

  // {Link.url(globalPrismicDataOutput.data[0].web_link)}

  console.log(prismicDataOutput.data.slide_1.url);
  console.log(prismicDataOutput);

  return prismicDataOutput;
}

async function GoogleSheetsConnector() {

  let sheetsDataOutput = [];

  let sheetsDocID = "";
  sheetsDocID = "1TpP3MtJ1BvCBIapcvvwXKhbc2hZ42bpH-P-Fjw3MoY0";

  // const doc = new GoogleSpreadsheet('<the sheet ID from the url>');
  const doc = new GoogleSpreadsheet(sheetsDocID);
  
  await doc.useServiceAccountAuth(require('./bnecd-dev2_google_credentials.json'));

  await doc.loadInfo();
  console.log(doc.title);

  const sheet = doc.sheetsByIndex[0];

  console.log(sheet.title);
  

  await sheet.loadCells();

  const col1Title = sheet.getCell(1, 1);
  const col1Image = sheet.getCell(1, 2);
  const col1Text = sheet.getCell(1, 3);

  const col2Title = sheet.getCell(2, 1);
  const col2Image = sheet.getCell(2, 2);
  const col2Text = sheet.getCell(2, 3);

  const col3Title = sheet.getCell(3, 1);
  const col3Image = sheet.getCell(3, 2);
  const col3Text = sheet.getCell(3, 3);

  const col4Title = sheet.getCell(4, 1);
  const col4Image = sheet.getCell(4, 2);
  const col4Text = sheet.getCell(4, 3);

  const col5Title = sheet.getCell(5, 1);
  const col5Image = sheet.getCell(5, 2);
  const col5Text = sheet.getCell(5, 3);

  const col6Title = sheet.getCell(6, 1);
  const col6Image = sheet.getCell(6, 2);
  const col6Text = sheet.getCell(6, 3);


  console.log(col1Title.value);
  console.log(col1Image.value);
  console.log(col1Text.value);

  console.log(col2Title.value);
  console.log(col2Image.value);
  console.log(col2Text.value);

  console.log(col3Title.value);
  console.log(col3Image.value);
  console.log(col3Text.value);

  console.log(col4Title.value);
  console.log(col4Image.value);
  console.log(col4Text.value);

  console.log(col5Title.value);
  console.log(col5Image.value);
  console.log(col5Text.value);

  console.log(col6Title.value);
  console.log(col6Image.value);
  console.log(col6Text.value);


  sheetsDataOutput[0] = col1Title.value;
  sheetsDataOutput[1] = col1Image.value;
  sheetsDataOutput[2] = col1Text.value;

  sheetsDataOutput[3] = col2Title.value;
  sheetsDataOutput[4] = col2Image.value;
  sheetsDataOutput[5] = col2Text.value;

  sheetsDataOutput[6] = col3Title.value;
  sheetsDataOutput[7] = col3Image.value;
  sheetsDataOutput[8] = col3Text.value;

  sheetsDataOutput[9] = col4Title.value;
  sheetsDataOutput[10] = col4Image.value;
  sheetsDataOutput[11] = col4Text.value;

  sheetsDataOutput[12] = col5Title.value;
  sheetsDataOutput[13] = col5Image.value;
  sheetsDataOutput[14] = col5Text.value;

  sheetsDataOutput[15] = col6Title.value;
  sheetsDataOutput[16] = col6Image.value;
  sheetsDataOutput[17] = col6Text.value;


  console.log(sheetsDataOutput);

  return sheetsDataOutput;

}

function XiboRegisterDisplay(xiboSoapUrl, xiboScreenID) {
  var xmlhttp = new XMLHttpRequest();
  // xmlhttp.open('POST', 'http://xibo.bnecd.local/xmds.php?wsdl&v=5', true);
  // xmlhttp.open('POST', 'http://xibo.bnecd.local/xmds.php?v=5', true);
  xmlhttp.open('POST', xiboSoapUrl, true);

  // build SOAP request
  var sr =
      '<?xml version="1.0" encoding="utf-8"?>' +
      '<soapenv:Envelope ' + 
          'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
          'xmlns:xsd="http://www.w3.org/2001/XMLSchema" ' +
          'xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" ' +
          'xmlns:urn="urn:xmds">' +
          '<soapenv:Header/>' +
          '<soapenv:Body>' +
              '<urn:RegisterDisplay soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">' +
                  '<serverKey xsi:type="xsd:string">Nl0jGwkD</serverKey>' +
                  '<hardwareKey xsi:type="xsd:string">' + xiboScreenID + '</hardwareKey>' +
                  '<displayName xsi:type="xsd:string">' + xiboScreenID + '</displayName>' +
              '</urn:RegisterDisplay>' +
          '</soapenv:Body>' +
      '</soapenv:Envelope>';

  xmlhttp.onreadystatechange = function () {
      if (xmlhttp.readyState == 4) {
          if (xmlhttp.status == 200) {
              // alert(xmlhttp.responseText);
              // alert('done. use firebug/console to see network response');

              console.log(sr);
              console.log("-----------");
              console.log(xmlhttp.responseText);
          }
      }
  }
  // Send the POST request
  xmlhttp.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
  // xmlhttp.setRequestHeader('SOAPAction', '"urn:xmds#RequiredFiles"');

  xmlhttp.send(sr);
  // send request
  // ...

}

function XiboSendScreenshot(xiboSoapUrl, xiboScreenID) {
  console.log("Output screenshot...");

  let screenshotPNGBase64 = "";
  let screenshotPNGBase64Proc = "";

  html2canvas(document.body).then(function(canvas) {
    // document.body.appendChild(canvas);

    // MK DEV -- disable raw screen >> console.log
    // console.log(canvas);
    // console.log(canvas.toDataURL());

    screenshotPNGBase64 = canvas.toDataURL();

    screenshotPNGBase64Proc = screenshotPNGBase64.substring(22);

    // MK DEV -- disable raw screen >> console.log
    // console.log(screenshotPNGBase64Proc);

    var xmlhttp = new XMLHttpRequest();
    // xmlhttp.open('POST', 'http://xibo.bnecd.local/xmds.php?wsdl&v=5', true);
    // xmlhttp.open('POST', 'http://xibo.bnecd.local/xmds.php?v=5', true);
    xmlhttp.open('POST', xiboSoapUrl, true);

    // build SOAP request
    var sr =
        '<?xml version="1.0" encoding="utf-8"?>' +
        '<soapenv:Envelope ' + 
            'xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" ' +
            'xmlns:xsd="http://www.w3.org/2001/XMLSchema" ' +
            'xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" ' +
            'xmlns:urn="urn:xmds">' +
            '<soapenv:Header/>' +
            '<soapenv:Body>' +
                '<urn:SubmitScreenShot soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">' +
                    '<serverKey xsi:type="xsd:string">Nl0jGwkD</serverKey>' +
                    '<hardwareKey xsi:type="xsd:string">' + xiboScreenID + '</hardwareKey>' +
                    '<screenShot xsi:type="xsd:base64Binary">' + screenshotPNGBase64Proc + '</screenShot>' +
                '</urn:SubmitScreenShot>' +
            '</soapenv:Body>' +
        '</soapenv:Envelope>';

    xmlhttp.onreadystatechange = function () {
        if (xmlhttp.readyState == 4) {
            if (xmlhttp.status == 200) {
                // alert(xmlhttp.responseText);
                // alert('done. use firebug/console to see network response');

                console.log(sr);
                console.log("-----------");
                console.log(xmlhttp.responseText);
            }
        }
    }
    // Send the POST request
    xmlhttp.setRequestHeader('Content-Type', 'text/xml; charset=utf-8');
    // xmlhttp.setRequestHeader('SOAPAction', '"urn:xmds#RequiredFiles"');

    xmlhttp.send(sr);
    // send request
    // ...

    });
}

export default App;
